import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { OneAuthService } from 'one-auth';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {

  constructor(private auth: OneAuthService) {
    super('en-US'); // set default locale
  }

  public format(date: moment.Moment, displayFormat: string): string {
    //const locale = this._dateTimeService.getLocale();
    //const format = this._dateTimeService.getFormat();
    let dt: string = this.auth.user.info.dateFormat;
    let dateformat = dt.toUpperCase()
    return date.format(dateformat);
  }
}
