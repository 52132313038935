import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment_ from 'moment';
import "moment-timezone";
import { OneAuthService } from 'one-auth';
import { CustomDateAdapter } from './services/custom-date-adapter.service';
const moment = moment_["default"];

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-us' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    //{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    //{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    //{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    CustomDateAdapter, // so we could inject services to 'CustomDateAdapter'
    { provide: DateAdapter, useClass: CustomDateAdapter }, // Parse MatDatePicker Format
  ],
})
export class DatePickerComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() field: any;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() set disabledPicker(condition: boolean) {
    if (condition) {
      this.parentForm['controls'][this.field].disable();
    } else {
      this.parentForm['controls'][this.field].enable();
    }
  }
  @Input() disabledate: string;
  @Input() placeholder: string;
  @Input() removeTime: boolean;
  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() rowValue: any = null;
  @Input() tz: boolean = false;

  timezoneLocale: string;
  dateFormats: string[] = [
    'dd/MM/yyyy',
    'dd/MM/yyyy hh:mm:ss',
    'dd-MM-yyyy',
    'dd-MM-yyyy HH:mm:ss',
    'MM/dd/yyyy',
    'MM/dd/yyyy hh:mm:ss',
    'yyyy/MM/dd',
    'yyyy/MM/dd HH:mm:ss',
    'dd/MM/yy',
    'dd/MM/yy hh:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSZZ'
  ];
  constructor(private adapter: DateAdapter<any>, private fb: FormBuilder, public auth: OneAuthService) { }

  dateChangeEvent(newdate) {
    let a = newdate.year() + '/' + (newdate.month() + 1) + '/' + newdate.date();
    let datePick: any;
    //if timeZone is true then it will return "2020-03-03T00:00:00.000Z" else else it will return 2020-03-03
    if (this.tz) {
      datePick = moment(a).utc(true).toISOString(); //toISOString() :- The timezone is always zero UTC offset. like "2020-03-15T00:00:00.000Z"
    } else {
      datePick = moment(a).utc(true).format('YYYY-MM-DD');
    }
    this.dateChanged.emit(datePick);
    if (this.rowValue) {
      if (datePick != 'Invalid date')
        this.rowValue.controls[this.field].setValue(datePick);
      else
        this.rowValue.controls[this.field].setValue('');
    } else {
      if (datePick != 'Invalid date')
        this.parentForm.get(this.field).setValue(datePick);
      else
        this.parentForm.get(this.field).setValue('');
    }
  }

  ngOnInit() {
    //this.getTimeZoneFromLocalstorage();
  }

  getTimeZoneFromLocalstorage() {
    //To do
    //add code to get time zone of user from local storage and assign to this.timezoneLocale variable
    this.timezoneLocale = 'Asia/Kolkata';
    this.adapter.setLocale('en-us');
  }

  //PMB-2322 changes for today date border
  dateClass = (dt: Date) => {
    let currentDate = moment().tz(this.auth.user.info.timeZone).format('YYYY-MM-DD');
    let newDate = moment(dt).format('YYYY-MM-DD');
    return moment(currentDate).isSame(newDate) ? 'today-date' : undefined;
  };

}
