import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';
import { CommonDialogComponent } from 'src/app/modules/shared-components/components/common-dialog/common-dialog.component';
@Component({
  selector: 'side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent implements OnInit {

  sideNav = [];
  carrierApp: any;

  carrierUserMenus: any = [
    { title: this.translate.instant("NAVBAR.LABEL.DASHBOARD"), route: '/dashboard', img: 'icon-dashboard' },
    { title: this.translate.instant("NAVBAR.LABEL.TASK_CENTER"), route: '/task-center-list', img: 'icon-task_centre' },
  ];
  public permissions: any;
  public dialogRef: any;
  navigationScrollButton = "none";

  constructor(private translate: TranslateService, private auth: OneAuthService, private route: ActivatedRoute, private router: Router, public msgDiag: MatDialog, private location: Location, private cdr: ChangeDetectorRef) {

  }
  ngOnInit() {
    //this.permissions=this.auth.user.permissions;
    // if(this.auth.user.id == undefined){
    //   console.log('user.id is undefined');
    //   this.auth.LoadAuth().then(()=>{
    //     //this.setnavBar(this.auth.user.permissions);
    //   });
    // } else { 
    //   this.setnavBar(this.auth.user.permissions);
    // }

    // this.auth.loadAuthFromStorage().then(resp => {
    //   this.setnavBar(resp.permissions);
    // });
    if (this.location.path() === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.location.path() === '/login') {
    } else {
      if (this.auth.user.permissions) {
        if (this.auth.user.permissions.CARRIER && this.auth.user.permissions.CARRIER.MODULES.LOGISTICS && this.auth.user.permissions.CARRIER.MODULES.LOGISTICS.actions.view) {
          this.permissions = this.auth.user.permissions;
        } else {
          if (this.location.path() === '') {
          }
          else {
            let msg = {
              title: 'No Permissions',
              error: 'Currently, no App/Module is assigned. Please contact your Administrator or support@procurant.com',
              code: 'E1004041001'
            };
            this.openDialog(msg);
          }
        }
      } else {
        if (this.location.path() === '') {
        }
        else {
          let msg = {
            title: 'No Permissions',
            error: 'Currently, no App/Module is assigned. Please contact your Administrator or support@procurant.com',
            code: 'E1004041001'
          };
          this.openDialog(msg);
        }
      }
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setNavigationScrollButton();
  }

  openDialog(msg) {
    if (!this.dialogRef) {
      this.dialogRef = this.msgDiag.open(CommonDialogComponent, { data: msg });
    }
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = undefined;
      this.auth.logoutUser();
      this.router.navigate(['login']);

    });
  }
  ngOnChanges() { }

  setnavBar(resp) {
    this.carrierApp = resp.CARRIER;
    // this.sideNav = this.carrierUserMenus;
    // let carrierApp = resp.CARRIER;
    // if (carrierApp) {
    //   if (carrierApp.MODULES.LOGISTICS) {
    //     if (carrierApp.MODULES.LOGISTICS.actions.view) {
    //       this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.LOGISTICS"), route: '/logistics/load-list', img: 'icon-logistics' });
    //     }
    //   }
    //   this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' });
    // } else {
    //   this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' });
    // }
  }

  scrollFunction() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollTop > 20) ? ((sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight - 60 >= 1) ? "both" : "up") : "down";
    // this.scrollPostion=(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight-60 >= 1) && (sidebar.scrollTop > 20);
  }

  //PMB-6554(RD) check scroll exits or not
  setNavigationScrollButton() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollHeight > sidebar.clientHeight) ? "down" : "none";

  }

  //PMB-6554(RD) set flag for up and down buttons
  handelScrollClick(direction) {
    var sidebar = document.getElementById('sidebar');
    sidebar.scrollTop = direction == "up" ? 0 : sidebar.clientHeight;
  }

}
