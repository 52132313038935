import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogisticsService } from '../../../order-logistics/services/logistics.service';

@Component({
  selector: 'org-details-popover',
  templateUrl: './org-details-popover.component.html',
  styleUrls: ['./org-details-popover.component.scss']
})
export class OrgDetailsPopoverComponent implements OnInit {

  @Input() orgId: number;
  @Input() orgName: String;
  orgDetailsData: any;

  constructor(private logisticsService: LogisticsService, private translate: TranslateService) { }

  ngOnInit() { }

  getOrgDetails(orgId) {
    this.logisticsService.getOrgDetails(orgId).subscribe((res) => {
      this.orgDetailsData = res;
    });
  }

}
