import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'content-infobar',
  templateUrl: './content-infobar.component.html',
  styleUrls: ['./content-infobar.component.scss']
})
export class ContentInfobarComponent implements OnInit {

  @Input()
  pageTitle: string;

  public breadcrumbs: { name: string; url: string }[] = [];
  constructor(
    public router: Router, public translate: TranslateService,
    public activatedRoute: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = [];
        this.parseRoute(this.router.routerState.snapshot.root);
      }
    })
  }

  parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        let urlSegments: UrlSegment[] = [];
        node.pathFromRoot.forEach((routerState, ind) => {
          urlSegments = urlSegments.concat(routerState.url);
        });
        const url = urlSegments.map(urlSegment => {
          return urlSegment.path;
        }).join('/');

        this.breadcrumbs.push({
          name: node.data['breadcrumb'],
          url: '/' + url
        })
        this.pageTitle = '';
        this.pageTitle = this.translate.instant(node.data['breadcrumb']);
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  ngOnInit() {
  }

}
