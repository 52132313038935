import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'is-what';
import * as moment_ from 'moment';
import { OneAuthService } from 'one-auth';
const moment = moment_["default"];
// translate service

@Pipe({
  name: 'pipes',
  pure: false // impure pipe, update value when we change language
})
export class ParsePipes implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe,
    private auth: OneAuthService) { }

  transform(value: string, dataType?: string, format?: string): any {
    if (!value)
      return dataType != 'date' ? 0 : '';
    else {
      //get Logedin user date format
      let dt: string = this.auth.user.info.dateFormat;
      let dateformat = dt.toUpperCase()
      //let dateformat: string;// = this.authService.getUser().dateFormat;
      if (format) {
        dateformat = format;
      }

      if (dateformat == '' || isNullOrUndefined(dateformat))
        dateformat = "MM/DD/YYYY";

      if (!dateformat) return;
      else {
        switch (dataType) {
          case "date":
            if (value == 'Multiple')
              return 'Multiple';
            return value == '' ? '' : moment(value).format(dateformat)  //dateFormat == 'Invalid date' ? "" : dateFormat;
          case "tzTime":
            if (value == 'Multiple')
              return 'Multiple';
            return value == '' ? '' : moment(value).tz(this.auth.user.info.timeZone).format(dateformat);
          case "tzTimeago":
            return moment.tz(value, this.auth.user.info.timeZone).fromNow()
          case "currancy":
            return this.currencyPipe.transform(value, this.getCurrancy(), 'symbol-narrow', '1.2-4');
          case "decimal":
            return this.decimalPipe.transform(value, '1.2-4');
          case "currancySymbol":
            return this.getCurrancySymbol();
          default:
            return value;
        }
      }
    }
  }

  public getCurrancySymbol() {
    const currency: any = window.sessionStorage['currency'];
    switch (currency) {
      case 'CNY':
        return '¥';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return '$';
    }
  }

  public getCurrancy() {
    const currency: any = window.sessionStorage['currency'];
    switch (currency) {
      case 'CNY':
        return 'CNY';
      case 'USD':
        return 'USD';
      case 'EUR':
        return 'EUR';
      default:
        return 'USD';
    }
  }
}

@Pipe({
  name: "sort",
  pure: false
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field].toUpperCase() < b[field].toUpperCase()) {
        return -1;
      } else if (a[field].toUpperCase() > b[field].toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
