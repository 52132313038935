import { environment } from './environment';

export const URLS = Object();

URLS.UserLogout = environment.BASE_API_URL + "auth/api/user/logout-internal";

URLS.UserLoginServiceAPI = environment.BASE_API_URL + "login";
URLS.LogoutAPI = environment.BASE_API_URL + "auth/api/user/logout";
URLS.UserInfoServiceAPI = environment.BASE_API_URL + "auth/api/user/info";
URLS.UserPermissionAPI = environment.BASE_API_URL + "auth/api/user/user-app-module-permissions";
URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";
URLS.GetProfileImage = environment.BASE_API_URL + "admin/api/object-store/view/"


URLS.GetAllOrgsDetailsAPI = environment.BASE_API_URL + "admin/getorgs"; // TBD

// Logistics Service
URLS.TradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/CARRIER/BUYER";
URLS.AssignedModelListAPI = environment.BASE_API_URL + "logistics/load/transmodes";
URLS.GetLoadListAPI = environment.BASE_API_URL + "logistics/load/carrier/list";
URLS.GetLoadPOSAPI = environment.BASE_API_URL + "logistics/load/carrier/fetchLoadPos/";

URLS.LoadFindAPI = environment.BASE_API_URL + "logistics/load/carrier/find/";
URLS.LoadCancel = environment.BASE_API_URL + "logistics/load/carrier/cancel";
URLS.RemoveOrderFromLoad = environment.BASE_API_URL + "logistics/load/remove/loadPOs/";

URLS.TaskcenterLoadCountAPI = environment.BASE_API_URL + "logistics/load/taskcenter-count-metadata?taskcenterTabs=";
URLS.GetTaskcenterLoadDetails = environment.BASE_API_URL + "logistics/load/load-taskcenter";
URLS.TriggerOffAPI = environment.BASE_API_URL + "logistics/load/task-center/trigger-off";

URLS.GetOrderCommentCount = environment.BASE_API_URL + "order/comment/count/PO";
URLS.GetOrderDetails = environment.BASE_API_URL + "order/po/short/";
URLS.SuggestLoadNumber = environment.BASE_API_URL + "logistics/load/carrier/search/suggest/";
URLS.SearchLoad = environment.BASE_API_URL + "logistics/load/carrier/search/";
URLS.GetOrgDetails = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetChargeSettings = environment.BASE_API_URL + "order/po/charge/setting/";
URLS.GetCharges = environment.BASE_API_URL + "admin/api/charge/list";
URLS.GetLoadChargeDetails = environment.BASE_API_URL + "logistics/load/carrier/charge/";
URLS.GetLoadPOChargeDetails = environment.BASE_API_URL + "logistics/load/carrier/loadPo/charge/";

URLS.BUYER_APPLINK = environment.BASE_API_URL + "buyer-app/#/app/dashboard";
URLS.SUPPLIER_APPLINK = environment.BASE_API_URL + "vendor-app/#/app/dashboard";
URLS.CARRIER_APPLINK = environment.BASE_API_URL + "carrier-app/#/app/dashboard";
URLS.TURST_APPLINK = environment.BASE_API_URL + "trust-app/dashboard";
URLS.COMMON_APPLINK = environment.BASE_API_URL + "common-app/#/dashboard";
URLS.BUYER_APPLINK = environment.BASE_API_URL + "buyer-app/dashboard";
URLS.SUPPLIER_APPLINK = environment.BASE_API_URL + "supplier-app/dashboard";
URLS.CARRIER_APPLINK = environment.BASE_API_URL + "carrier-app/dashboard";
URLS.TURST_APPLINK = environment.BASE_API_URL + "trust-app/dashboard";

URLS.GetOrgBusinessTypeAPI = environment.BASE_API_URL + "admin/api/org/public/business-types";

URLS.CountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.StateListAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";

URLS.OrgNameExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-name/";
URLS.BusinessEmailExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-email/";
URLS.DunsNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/duns-exists-with-otherOrg/";
URLS.AddCompanyAPI = environment.BASE_API_URL + "admin/api/org/public/create";

URLS.GetAllLoadStatusListAPI = environment.BASE_API_URL + "logistics/load/get-load-statuses";
//route details
URLS.GetRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/find-route/";
URLS.CreateRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/create-route-details";
URLS.UpdateRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/update-route-details";
URLS.GetLoadStatusesRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/get-route-details-load-statuses";

//My preferences
URLS.GetFetchNotifications = environment.BASE_API_URL + "admin/api/user/preferences/notifications/fetch/";
URLS.SaveEmailNotification = environment.BASE_API_URL + "admin/api/user/preferences/notifications/save-preferences";

// Get Filter
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter"; //(RS)PMB-8853 api to save filter for carrier load list
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/"; //(RS)PMB-8853 api to get filter for carrier load list

//user profile
URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetUserDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";
URLS.GetUser = environment.BASE_API_URL + "admin/api/user/fetchUser/";
URLS.GetUserProfile = environment.BASE_API_URL + "admin/api/user/me";
URLS.UpdateUserProfile = environment.BASE_API_URL + "admin/api/user/updateUserProfile";
URLS.ChangeUserProfilePasswordAPI = environment.BASE_API_URL + "admin/api/user/change-password";

//Load Upload
URLS.LoadFileUpload = environment.BASE_API_URL + "logistics/api/document/load/upload?id=";
URLS.FetchLoadLevelFiles = environment.BASE_API_URL + "logistics/api/document/load/all/"
URLS.DeleteLoadDocument = environment.BASE_API_URL + "logistics/api/document/delete?objectId=";
URLS.GetLoadFileObjectDocument = environment.BASE_API_URL + "logistics/api/object-store/download/";
URLS.GetLoadFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.GetClaimsCommentCount = environment.BASE_API_URL + "logistics/comment/count/LOAD";
URLS.setLoadIndicatorAsViwedAPI = environment.BASE_API_URL + "logistics/load/indicator/delete";
URLS.GetLoadHeaderIndicators = environment.BASE_API_URL + "logistics/load/load-indicator/";
URLS.GetLoadShipFrom = environment.BASE_API_URL + "order/po/find-shipFrom/";

URLS.createFreightInvoice = environment.BASE_API_URL + "logistics/freightInvoice/create";
URLS.CheckInvoiceNoExists = environment.BASE_API_URL + "logistics/freightInvoice/exists-by-invoiceNumber/";
URLS.getFreightInvoiceList = environment.BASE_API_URL + "logistics/freightInvoice/carrier/list";
URLS.SuggestInvoiceNumber = environment.BASE_API_URL + "logistics/freightInvoice/carrier/search/suggest/";
URLS.getFreightInvoiceHeader = environment.BASE_API_URL + "logistics/freightInvoice/carrier/invoice-header/";
URLS.LoadFindAPIByInvoiceId = environment.BASE_API_URL + "logistics/freightInvoice/carrier/find/";
URLS.getLoadDetailsSummary = environment.BASE_API_URL + "logistics/freightInvoice/carrier/load-total-summary/";
URLS.GetInvoiceHeaderIndicators = environment.BASE_API_URL + "logistics/freightInvoice/invoice-indicator/";
URLS.GetOrgsDetails = environment.BASE_API_URL + "admin/api/org/getOrganizationShortDetails";
URLS.GetBillToList = environment.BASE_API_URL + "admin/api/org/getOrganizationByBizUnit";

URLS.GetBuyerCompanyList = environment.BASE_API_URL + 'admin/api/trading-partners/my-trading-partners/CARRIER/BUYER';
URLS.GetBuyerUserListByModuleCode = environment.BASE_API_URL + "admin/api/user/buyers-user-list-without-department/";
URLS.GetDeliveryToAPI = environment.BASE_API_URL + "admin/api/org/trading-partner/businessUnits/";

URLS.updateLoadStatus = environment.BASE_API_URL + "logistics/load/update/";


