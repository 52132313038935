import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';
import { filter } from 'rxjs/operators';
import { InitLoaderService } from './services/initloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private page: string;
  //public isAuthenticated: boolean = false;

  public isLogin: boolean = false;
  public isAppSelector = false;
  pageTitle: string;

  constructor(private init: InitLoaderService, private auth: OneAuthService, public translate: TranslateService, public router: ActivatedRoute, public route: Router, private location: Location, private titleService: Title) {
    this.translate.setDefaultLang('en_US');
    this.init.load();
    //Below code is used to reload page on same nav menu clicked
    // this.route.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // }
    // this.route.events.subscribe((evt) => {
    //   if (evt instanceof NavigationEnd) {
    //     // trick the Router into believing it's last link wasn't previously loaded
    //     this.route.navigated = false;
    //     // if you need to scroll back to top, here is the right place
    //     window.scrollTo(0, 0);
    //   }
    // });
  }

  ngOnInit() {

    // if(this.auth.user.info.id === undefined){
    //   console.log("no user data");
    //   this.auth.LoadAuth().then(()=>{

    //   });
    // }
    //PMB-
    //uncomment below line to check internal admin login
    //this.auth.user.info.internalAdmin = true;

    this.route.events.subscribe((pat) => {
      if (this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout") {

        if (!this.isLogin) {
          this.isLogin = true;
        }
      } else if (this.route.url !== "/BBF65054CC9C2C8CB6692B2A1648FD8C" && this.route.url !== "/logout") {

        if (this.isLogin) {
          this.isLogin = false;
        }
      }

    });

    this.route.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      var root = this.route.routerState.snapshot.root;
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0];
        } else if (root.data && root.data["title"]) {
          this.pageTitle = root.data["title"];
          this.pageTitle = this.translate.instant(this.pageTitle);
          this.titleService.setTitle(this.pageTitle)
          return;
        } else {
          return;
        }
      }
    });
  }

  // ngAfterViewInit(){
  //   if(this.location.path() == "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.location.path() == "/logout"){
  //     console.log('settig login view to true')
  //     this.isLogin = true;
  //   } else{
  //     console.log('settig login view to false')
  //     this.isLogin = false;
  //   }
  // }

  // This is temporary till the App Based Login is done
  doTemporaryLogin() {
    /*  //this.appStorage.loadMeta();
     let u = "retailer@procurant.com";
     let p = "password";
     // let u = "roryfields@acme.com";
     //         let p = "Password19!";
     // let u = "buyer-1@procurant.com";
     // let p = "%Password19";
     this.auth.validate(u, p).subscribe((res) => {
       this.auth.getUserInfoPermission().subscribe((res) => {
         //this.appStorage.loadMeta();
       })
     }) */
  }

}
