<side-nav>
    <div class="navBarHeight" sidenavitem>
        <!-- <a mat-list-item *ngFor="let link of sideNav" routerLink="{{link.route}}" routerLinkActive="active" title="{{link.title}}">
            <span class="sideBarIco {{link.img}}"></span>
        </a> -->
        <!-- <a mat-list-item routerLink="/dashboard" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
            <span class="sidebar-icon icon-dashboard"></span>
            {{ 'NAVBAR.LABEL.DASHBOARD' | translate }}
        </a>-->
        <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
        <div class="nav-new-bar">
            <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
                (click)="handelScrollClick('up')">
                <img src="../../../../../assets/images/up_double.png" alt="">
            </div>
            <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
                <a mat-list-item routerLink="/task-center" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.TASK_CENTER' | translate}}"
                    *ngIf="permissions?.CARRIER?.MODULES?.LOGISTICS && permissions?.CARRIER?.MODULES?.LOGISTICS?.actions?.view">
                    <!-- <span class="sidebar-icon icon-task_centre"></span> -->
                    <div class="sprite-container taskcenter">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.TASK_CENTER' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/logistics/load-list" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.LOGISTICS' | translate}}"
                    *ngIf="permissions?.CARRIER?.MODULES?.LOGISTICS && permissions?.CARRIER?.MODULES?.LOGISTICS?.actions?.view">
                    <!-- <span class="sidebar-icon icon-logistics"></span> -->
                    <div class="sprite-container logistics">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.LOGISTICS' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/freight-invoice/freight-invoice-list" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.FRIEGHT_INVOICE' | translate}}"
                    *ngIf="permissions?.CARRIER?.MODULES?.LOGISTICS && permissions?.CARRIER?.MODULES?.LOGISTICS?.actions?.view">
                    <!-- <span class="sidebar-icon icon2-invoice"></span> -->
                    <div class="sprite-container invoice">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.FRIEGHT_INVOICE' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/my-preferences" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
                    <!-- <span class="sidebar-icon icon2-settings"></span> -->
                    <div class="sprite-container preferences">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
                </a>
            </div>
            <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
                (click)="handelScrollClick('down')">
                <img src="../../../../../assets/images/down_double.png" alt="">
            </div>
        </div>

        <div class="connect-link">
            <a mat-list-item routerLink="/connect" target="_blank" routerLinkActive="active"
                title="{{'NAVBAR.LABEL.CONNECT' | translate}}">
                <!-- <span class="sidebar-icon icon-Group"></span> -->
                <img src="../../../../../assets/images/Group.png">
                <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
            </a>
        </div>
    </div>

</side-nav>