import { Location } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GridModule } from '@progress/kendo-angular-grid';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';

import { MaterialUiModule } from './modules/material-ui/material-ui.module';

import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { HttpRequestInterceptor } from './services/http-interceptor.service';

import { OneAuthModule, OneAuthService } from 'one-auth';
import { environment } from 'src/environments/environment';
import { RedirectGuard } from './services/redirect-guard';

import { RouterModule } from '@angular/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { InitLoaderService } from './services/initloader.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialUiModule,
    SharedComponentsModule,
    AppToolbarsModule,
    ReactiveFormsModule,
    FormsModule,
    GridModule,
    RouterModule,
    DevLoginModule,
    StorageModule.forRoot({ IDBNoWrap: false }),
    OneAuthModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    OneAuthService,
    Location,
   
    HttpRequestInterceptor,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    RedirectGuard,
    InitLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}