<div class="diag-header">
    <h2 mat-dialog-title>{{msg.title}}</h2>
      <!-- <div class="closex">X</div> -->
    </div>
    <mat-dialog-content>
      <div class="dialog-message">
        {{msg.error}}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [ngClass]="[(msg != null && (msg.code == 'E1004011001' || msg.code == 'E1005021001')) ? 'btn btnPrimary' : '']" (click)="closeDialog()">
        {{(msg != null && (msg.code == 'E1004011001' || msg.code == 'E1005021001')) ? 'Click here to login' : 'Close'}}
      </button>
    </mat-dialog-actions>