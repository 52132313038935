import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OneNotifyService } from 'one-auth';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { URLS } from '../../../../environments/config.url';

@Injectable({
  providedIn: 'root'
})
export class LogisticsService {
  loading = false;
  private headers: HttpHeaders = new HttpHeaders();
  constructor(public http: HttpClient, public dialog: MatDialog, private transalate: TranslateService, public notify: OneNotifyService) { }
  
  getAssignCarriersList(): Observable<any> {
    return this.http.get<any>(URLS.TradingPartnerAPI).pipe(
      map((res) => {
        return res;
      }));
  }

  getAssignModesList(): Observable<any> {
    return this.http.get<any>(URLS.AssignedModelListAPI).pipe(
      map((res) => {
        return res;
      }));
  }

  getLoadList(requestData): Observable<any> {
    this.loading = true;
    return this.http.post<any>(URLS.GetLoadListAPI, requestData, { headers: this.headers }).pipe(
      map((res) => {

        return res;
      }, err => {
        this.loading = false
      }), tap(() => this.loading = false));;
  }

  getLoadPos(loadId, sortColumn, sortOrder): Observable<any> {
    let url = URLS.GetLoadPOSAPI + loadId + "?sortColumn=" + sortColumn + "&sortOrder=" + sortOrder;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getLoadDetails(id, isTaskcenter = false): Observable<any> {
    let url = URLS.LoadFindAPI + id + "/" + isTaskcenter;
    return this.http.get<any>(url, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrderCommentCount(id): Observable<any> {
    let url = URLS.GetOrderCommentCount + "?&trxDocIds=" + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrderDetails(id): Observable<any> {
    let url = URLS.GetOrderDetails + id;
    return this.http.get<any>(url).pipe(
      map((res: Response) => {
        return res;
      }));
  }

  extractData(response): Observable<any> {
    return response.body;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  suggestLoadNumber(loadNumber: any) {
    let loadStatus = 'Sent';
    let url = URLS.SuggestLoadNumber + loadNumber;// + "?&loadStatus=" + loadStatus;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  searchLoad(loadNumber: any) {
    let loadStatus = 'Sent';
    let url = URLS.SearchLoad + loadNumber;// + "?&loadStatus=" + loadStatus;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res
      }));
  }

  getOrgDetails(orgId) {
    let url = URLS.GetOrgDetails + orgId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getChargeSetting(settingType) {
    let url = URLS.GetChargeSettings + settingType;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getCharges() {
    let url = URLS.GetCharges + "?&chargeOn=load";
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getLoadChargeDetails(loadId, loadPOId?) {
    /* let url = URLS.GetLoadChargeDetails + loadId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })); */

    this.loading = true;
    let url = loadPOId == undefined || loadPOId == null ? URLS.GetLoadChargeDetails + loadId : URLS.GetLoadPOChargeDetails + loadId + "/" + loadPOId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }), tap(() => this.loading = false));
  }

  cancelLoadMethod(param) {
    return this.http.post<any>(URLS.LoadCancel, param, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  removeOrderFromLoad(param) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    let poids = param.input.po.map(a => a.poId);
    let ids = new HttpParams().set('ids', poids);
    return this.http.delete(URLS.RemoveOrderFromLoad + param.input.loadDetailsData.id, { params: ids, responseType: 'text' }).pipe(
      map((res) => {
        return res;
      }));
  }

  getRouteDetails(id): Observable<any> {
    let url = URLS.GetRouteDetailsAPI + id;
    return this.http.get<any>(url, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

  createRouteDetails(data): Observable<any> {
    return this.http.post(URLS.CreateRouteDetailsAPI, data, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateRouteDetails(data): Observable<any> {
    return this.http.put(URLS.UpdateRouteDetailsAPI, data, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getLoadStatusList(): Observable<any> {
    let url = URLS.GetLoadStatusesRouteDetailsAPI;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  
  setIndicatorAsViwed(reqData) {
    //let url = URLS.setIndicatorAsViwedAPI;
    let url = URLS.setLoadIndicatorAsViwedAPI;
    return this.http.post(url, reqData, { headers: this.headers, responseType: 'text' })
  }

  /* Order Documents */
  uploadPODoc(id, data, levelOfUpload) {
    let headers = new HttpHeaders();
    // Add Content-Type be default
    headers.set('Content-Type', 'application/json');
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.POLineFileUpload + id
    } else {
      url = URLS.LoadFileUpload + id
    }
    return this.http.post(url, data, {
      reportProgress: true,
      observe: 'events',
      headers: this.headers,
      responseType: 'text'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response: {
          this.notify.show(this.transalate.instant('COMPANY.MESSAGE.DCO_UPLOAD_SUCCESS'));
          return event.body;
        }
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  getFileObject(objId): Observable<any> {
    let url = URLS.GetLoadFileObjectViewAPI + objId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
        // return this.extractData(res);
      }));
    // return orgData;
  }

  FetchPODocument(id, levelOfUpload) {
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.FetchPOLineLevelFiles + id
    } else {
      url = URLS.FetchLoadLevelFiles + id
    }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  DeleteLoadDocument(id) {
    let url = URLS.DeleteLoadDocument + id;
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  checkParellelTransaction(id, versioning) {
    let url = URLS.CheckParellelTransactions + '/' + id + '/' + versioning;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getHeaderIndicators(id, statusCode, isCarrier): Observable<any> {
    let url = URLS.GetLoadHeaderIndicators + id + '/' + statusCode + '/' + isCarrier;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  findShipFromListByPoId(poNumber: any) {
    let url = URLS.GetLoadShipFrom + poNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res
      }));
  }

  createFreightInvoice(data): Observable<any> {
    return this.http.post(URLS.createFreightInvoice, data, { headers: this.headers, observe: 'response'}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  isInvoiceNumberExists(invNo): Observable<any> {
    let url = URLS.CheckInvoiceNoExists + invNo;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  
  getOrgShortDetails(orgId) {
    let url = URLS.GetOrgsDetails + "?ids="+[orgId];
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }
  getBillToList(orgId) {
    // let url = URLS.GetRemitToList + orgId + '/REMIT_TO';
    let url = URLS.GetBillToList + '?orgId=' + orgId + '&bizunit=BILL_TO';
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateLoadStatus(id, statusCode): Observable<any> {
    return this.http.put<any>(URLS.updateLoadStatus + id + '/' + statusCode,{ headers: this.headers });
  }

  getAllLoadStatusList(): Observable<any> {
    return this.http.get<any>(URLS.GetAllLoadStatusListAPI).pipe(
      map((res) => {
        return res;
      }));
  }

  getBuyerCompanyList() {
    return this.http.get(URLS.GetBuyerCompanyList, { headers: this.headers })
  }

  getBuyerUserListByCompanyIds(moduleCode, buyerCompanyIds): Observable<any> {
    let url = URLS.GetBuyerUserListByModuleCode + moduleCode + "?selectedBuyersIds=" + buyerCompanyIds;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  
  getDeliveryLocations(): Observable<any> {
    return this.http.get<any>(URLS.GetDeliveryToAPI + "CARRIER/BUYER/DELIVERY").pipe(
      map((res) => {
        return res;
      }
      ));
  }
//(RS)PMB-8853 api to save filter for carrier load list
  saveFilter(data): Observable<any> {
    return this.http.post(URLS.SaveFilterAPI, data, { headers: this.headers, responseType: 'text' })
  }
  
  //(RS)PMB-8853 api to get filter for carrier load list
  getFilterList(appCode, moduleCode, category, subCategory): Observable<any> {
    let url = URLS.GetFilterListAPI + appCode + '/' + moduleCode + '/' + category + '/' + subCategory;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
