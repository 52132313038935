<span style="cursor: pointer;" [mdePopoverTriggerFor]="myPopOver" mdePopoverTriggerOn="click" (click)="getOrgDetails(orgId)">
    {{orgName}}
  <mde-popover #myPopOver="mdePopover" [mdePopoverOverlapTrigger]="false" style="max-width: 320px">
    <mat-card (click)="$event.stopPropagation();">
      <ng-container *ngIf="orgDetailsData">
        <div fxLayout="row" fxLayoutAlign="start start">
          <div class="tooltipText">
            <div fxLayout="column" fxLayoutAlign="start none" class="infoBlocks">
                <span class="tooltip-head">{{orgName}}</span>
              <div class="readOnlyField mb-15" fxLayout="row" fxLayoutAlign="space-between none">
                <div>
                  <span class="fieldLabel fs-12"><i class="icon2-user mr-5"></i>{{ 'LABEL.CONTACT_PERSON' | translate  }}</span>
                  <div class="fieldValue fs-14">{{orgDetailsData.user.fname}} {{orgDetailsData.user.lname}}
                  </div>
                </div>
              </div>
              <div class="readOnlyField mb-15" fxLayout="row" fxLayoutAlign="space-between none">
                <div class="fieldGroup">
                  <span class="fieldLabel fs-12"><i class="icon-messages mr-5"></i>{{ 'LABEL.EMAIL' | translate }}</span>
                  <div class="fieldValue fs-14">{{orgDetailsData.organization.email}}</div>
                </div>
              </div>
              <div class="readOnlyField mb-15" fxLayout="row" fxLayoutAlign="space-between none">
                <div class="fieldGroup">
                  <span class="fieldLabel fs-12"><i class="icon2-mobile mr-5"></i>{{ 'LABEL.PHONE' | translate }}</span>
                  <div class="fieldValue fs-14">
                      {{orgDetailsData.user.phone}}
                  </div>
                </div>
              </div>
              <div class="readOnlyField" fxLayout="row" fxLayoutAlign="space-between none">
                <div class="fieldGroup">
                  <span class="fieldLabel fs-12"><i class="icon-location mr-5"></i>{{ 'LABEL.ADDRESS' | translate }}</span>
                  <div class="fieldValue fs-14">{{orgDetailsData.organization.defaultAddress.address}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </mde-popover>
</span>