import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DevLoginComponent } from './modules/dev-login/components/dev-login/dev-login.component';
import { PageNotFoundComponent } from './modules/shared-components/components/page-not-found/page-not-found.component';
import { RedirectGuard } from './services/redirect-guard';
/* import { AppSelectorComponent } from './modules/one-login/components/app-selector/app-selector.component';
import { OneLoginComponent } from './modules/one-login/components/one-login/one-login.component';
import { OneLogoutComponent } from './modules/one-login/components/one-logout/one-logout.component';
import { PageNotFoundComponent } from './modules/shared-components/components/page-not-found/page-not-found.component'; */

/* const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'logistics',
        pathMatch: "full"
      },
      {
        path: 'logistics',
        loadChildren: './modules/order-logistics/order-logistics.module#OrderLogisticsModule'
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
]; */

const routes: Routes = [
  /* {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'logistics',
    loadChildren: './modules/order-logistics/order-logistics.module#OrderLogisticsModule'
  },
  {
    path: 'login',
    component: OneLoginComponent
  },
  {
    path: 'logout',
    component: OneLogoutComponent
  },
  {
    path: 'apps',
    component: AppSelectorComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  } */
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'dashboard',
    redirectTo: 'task-center'
  },
  {
    path: 'logistics',
    loadChildren: () => import('./modules/order-logistics/order-logistics.module').then(m => m.OrderLogisticsModule)
  },
  {
    path: 'task-center',
    loadChildren: () => import('./modules/task-center/task-center.module').then(m => m.TaskCenterModule),
  },
  {
    path: 'my-preferences',
    loadChildren: () => import('./modules/my-preferences/my-preferences.module').then(m => m.MyPreferencesModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: 'freight-invoice',
    loadChildren: () => import('./modules/freight-invoice/freight-invoice.module').then(m => m.FreightInvoiceModule)
  },
  {
    path: 'login',
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/logout"
    }
  },
  {
    path: 'apps',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/apps"
    }
  },
  {
    path: 'connect',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'connect' + environment.DOMAIN + '/industry-analytics?tab=true'
    }
  },
  {
    path: 'internal-admin',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'admin' + environment.DOMAIN + '/internal-admin'
    }
  },
  {
    path: "BBF65054CC9C2C8CB6692B2A1648FD8C",
    component: DevLoginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
