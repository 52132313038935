import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxUploaderModule } from 'ngx-uploader';
import { HttpRequestInterceptor } from '../../services/http-interceptor.service';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { CommentsContainerComponent } from './components/comments/comments-container/comments-container.component';
import { CommentsOverlayComponent } from './components/comments/comments-overlay/comments-overlay.component';
import { CommentsService } from './components/comments/comments.service';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { OrgDetailsPopoverComponent } from './components/org-details-popover/org-details-popover.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { ArraySortPipe, ParsePipes } from './pipes';
import { StorageService } from './services/storage.service';

@NgModule({
    declarations: [
        ParsePipes,
        ArraySortPipe,
        DatePickerComponent,
        OrgDetailsPopoverComponent,
        CommentsContainerComponent,
        CommentsOverlayComponent,
        ProcurantLoadingComponent,
        CommonDialogComponent,
        CommonNotificationComponent,
        CommonSnackbarComponent,
        PageNotFoundComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialUiModule,
        FlexLayoutModule,
        MdePopoverModule,
        TranslateModule,
        NgxUploaderModule,
        InfiniteScrollModule
    ],
    exports: [
        ParsePipes,
        ArraySortPipe,
        DatePickerComponent,
        FlexLayoutModule,
        MdePopoverModule,
        TranslateModule,
        InfiniteScrollModule,
        OrgDetailsPopoverComponent,
        CommentsContainerComponent,
        CommentsOverlayComponent,
        ProcurantLoadingComponent,
        CommonDialogComponent,
        PageNotFoundComponent,
        FormsModule,
        NgxUploaderModule,
        ReactiveFormsModule
    ],
    providers: [
        HttpRequestInterceptor,
        DatePipe,
        CurrencyPipe,
        DecimalPipe,
        ParsePipes,
        CommentsService,
        StorageService
    ]
})
export class SharedComponentsModule { }
